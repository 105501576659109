import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import request from "../../request";

class TransferStatusBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: this.props.status,
      hidden: false
    };
  }

  componentDidMount() {
    let interval = setInterval(() => {
      $.getJSON(`/transfers/${this.props.transferId}/status`, data => {
        const status = data.status;
        console.log(status);
        if (this.failOrSuccess(status)) {
          this.cancelTimeout();
        }
        this.setState({ status: data.status });
      });
    }, 3000);
    window.interval = interval;
  }

  cancelTimeout() {
    clearInterval(window.interval);
  }

  failOrSuccess(status) {
    return status === "failed" || status === "success";
  }

  message() {
    switch (this.state.status) {
      case "initiated":
      case "in_progress":
      case "getting_permissions":
        return "Metsätietojen päivitys on käynnissä ";
      case "success":
        return "Metsätiedot päivitetty onnistuneesti ";
      case "failed":
        return "Metsätietojen päivitys epäonnistui ";
    }
  }

  markReportViewed() {
    request.markTransferViewed(this.props.transferId);
    this.setState({ hidden: true });
  }

  renderCompletedTransfer() {
    return (
      <div className="transfer-notification__inner">
        <div className="transfer-notification__icon">
          <i className="opux-icon opux-icon-check" />
        </div>
        <div className="transfer-notification__body child-margins-y-0-25">
          <p>
            {this.message()}
            <a href={`/transfers/${this.props.transferId}`}>Katso tilanne</a>
          </p>
        </div>
        <div className="transfer-notification__close">
          <div
            className="transfer-notification__hide"
            onClick={this.markReportViewed.bind(this)}
          >
            <i className="opux-icon opux-icon-close" />
          </div>
        </div>
      </div>
    );
  }

  renderInprogressTransfer() {
    return (
      <div className="transfer-notification__inner">
        <div className="transfer-notification__icon">
          <div className="transfer-notification__loader">
            <div className="dot" />
            <div className="dot" />
            <div className="dot" />
          </div>
        </div>
        <div className="transfer-notification__body child-margins-y-0-25">
          <p>
            {this.message()}
            <a href={`/transfers/${this.props.transferId}`}>Katso tilanne</a>
          </p>
        </div>
      </div>
    );
  }

  renderView() {
    if (this.failOrSuccess(this.state.status)) {
      return this.renderCompletedTransfer();
    } else {
      return this.renderInprogressTransfer();
    }
  }

  render() {
    if (this.state.hidden) {
      return null;
    } else {
      return (
        <div className="container container--no-padding">
          {this.renderView()}
        </div>
      );
    }
  }
}

TransferStatusBanner.propTypes = {
  transferId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired
};

export default TransferStatusBanner;
