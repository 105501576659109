import React, {Component} from "react";

class TransferActive extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  progressTrackerItem(number, activeNumber) {
    if (number === activeNumber) {
      return "progress-tracker__item progress-tracker__item--active";
    } else if (number < activeNumber) {
      return "progress-tracker__item progress-tracker__item--complete";
    } else {
      return "progress-tracker__item";
    }
  }

  render() {
    return (
      <div>
        <div className="padding-1-5 padding-y text-align-center">
          <div className="margin-1 margin-bottom">
            <span className="opux-loading--large" />
          </div>
          <h3 className="size-1-5 margin-0-5 margin-bottom">
            Metsätietojasi siirretään
          </h3>
          <p className="size-1 color-gray-darken-1">
            Odota hetki. Tietojen siirto voi kestää useita minuutteja.
          </p>
        </div>
        <div className="transfer-message">
          <div className="padding-1 text-align-center">
            <p className="size-1 color-gray-darken-1">
              {this.props.statusText}
            </p>
          </div>
          <div className="border-top border-color-border padding-1 padding-y">
            <ul className="progress-tracker">
              <li
                data-step="1"
                className={this.progressTrackerItem(1, this.props.step)}>
                Kiinteistölista
              </li>
              <li
                data-step="2"
                className={this.progressTrackerItem(2, this.props.step)}>
                Metsätilat
              </li>
              <li
                data-step="3"
                className={this.progressTrackerItem(3, this.props.step)}>
                Arvon laskenta
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

TransferActive.propTypes = {};

export default TransferActive;
