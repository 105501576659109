import request from "./request";
document.addEventListener("turbolinks:load", function(event) {
  estateNameClickListener();
  editFieldClick();
  saveNameBtnClick();
});

function estateNameClickListener() {
  window.addEventListener("click", function(e){
    let insideNameContainer = !!$(e.target).parents(".estateNameContainer").get(0);
    if (insideNameContainer){
      // We do nothing
    } else {
      $("div.saveEstateName:visible").each(function(i) {
        let editForm = $(this).siblings(".editEstateName");
        $(this).hide();
        editForm.css("display", "flex");
      });
    }
  });
}

function editFieldClick() {
  $(".addEstateName, .editEstateName").on("click", function(event){
    let target = $(event.currentTarget);
    let estateNameForm = target.siblings(".saveEstateName");
    let nameInput = target.siblings("input#estate_name");

    target.hide();
    estateNameForm.css("display", "flex");
    nameInput.focus();
  });
}

function saveNameBtnClick() {
  $(".estateNameSaveBtn").on("click", function(event){
    event.preventDefault();
    let button = $(event.target);
    button.css("disabled", true);

    let nameForm = button.parents(".saveEstateName");

    clearError(nameForm);

    let name = nameForm.find("input.nameInput").val();
    let itemId = nameForm.find("input.idInput").val();
    let valuationId = nameForm.find(".valuationIdInput").val();
    let className = nameForm.find(".classNameInput").val();

    let payLoad = (function payloadResolution() {
      if (className === "ForestValuationItem") {
        return {
          name: name
        };
      } else if (className === "Property") {
        return {
          name: name,
          property_id: itemId
        };
      }
    })();

    let requestUrl = (function urlResolution() {
      if (className === "ForestValuationItem") {
        return `/metsaarvio/${valuationId}/forest_valuation_items/${itemId}/update_name`;
      } else if (className === "Property") {
        return "/home/update_property_name";
      }
    })();

    request
      .propertyNameUpdate(payLoad, requestUrl)
      .then((response) => {
        handleNameResponse(nameForm, response);
      })
      .fail((response) => {
        handleNameError(nameForm, response);
      })
      .always((response) => {
        button.css("disabled", "false");
      });
  });
}

function handleNameResponse(nameForm, response) {
  let editElement = nameForm.siblings(".editEstateName");
  let addElement = nameForm.siblings(".addEstateName");

  editElement.children("p").text(response.name);
  editElement.siblings("h3").text(response.nameWithArea);
  nameForm.hide();
  if (response.name.length === 0) {
    addElement.css("display", "flex");
  } else {
    editElement.css("display", "flex");
  }
}

function handleNameError(nameForm, response) {
  let errorElement = nameForm.siblings(".nameSaveError");

  errorElement.text(response.error);
  errorElement.show();
}

function clearError(nameForm) {
  let errorElement = nameForm.siblings(".nameSaveError");

  errorElement.text("");
  errorElement.hide();
}

