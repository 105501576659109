import React, { Component } from "react";
import PropTypes from "prop-types";
import PercentagePieChart from "./../../PercentagePieChart";
import { formatCurrency } from "../../../helpers/currency";
import { formatVolume } from "../../../helpers/volume";
import _ from "lodash";

const TREE_COLORS = {
  birch: "#969696",
  pine: "#ff6a10",
  spruce: "#6e6e6e"
};

const TREE_TYPES = {
  birch: "Koivu",
  pine: "Mänty",
  spruce: "Kuusi"
};

class PropertyDetailsTimber extends Component {
  renderTimberTableRows() {
    const { priceCalculations } = this.props;
    const timberRows = _.orderBy(
      priceCalculations,
      "percent_from_total_volume",
      "desc"
    ).filter(item => item.values.saw_logs !== 0 || item.values.pulpwood !== 0).map((item, index) => {
      const treeType = TREE_TYPES[item.tree_type];
      return (
        <tr key={item.tree_type} className="border-color-border">
          <td className="padding-0-5 padding-left">
            <span className="size-1">
              <span
                className="margin-0-25 margin-right"
                style={{
                  display: "inline-block",
                  width: 16,
                  height: 16,
                  borderRadius: 10,
                  background: TREE_COLORS[item.tree_type]
                }}
              />
              <span className="top-1">{treeType}</span>
            </span>
          </td>
          <td className="padding-0-5 text-align-right">
            <span className="color-gray-darken-2 size-1 block">
              {formatCurrency(item.values.saw_logs)}
            </span>
            <span className="color-gray-darken-1 size-0-75 block">
              {formatVolume(item.volumes.saw_logs)}
            </span>
          </td>
          <td className="padding-0-5 padding-left-0 text-align-right">
            <span className="color-gray-darken-2 size-1 block">
              {formatCurrency(item.values.pulpwood)}
            </span>
            <span className="color-gray-darken-1 size-0-75 block">
              {formatVolume(item.volumes.pulpwood)}
            </span>
          </td>
        </tr>
      );
    });
    return timberRows;
  }

  renderTimberGraph() {
    let data = this.props.timberTypeAllocation;
    data = data
      .filter(datum => {
        return datum.value !== 0;
      })
      .map(datum => {
        return Object.assign(
          { title: TREE_TYPES[datum.name], fill: TREE_COLORS[datum.name] },
          datum
        );
      });
    const graph = <PercentagePieChart data={data} />;
    return graph;
  }

  renderTimberDetailsList() {
    const { totalTreeVolume, averageTreeVolume } = this.props;
    return (
      <h4>
        <div className="size-1 nowrap color-gray-darken-1">
          Puuston kokonaistilavuus{" "}
          <span className="color-gray-darken-2">
            {formatVolume(totalTreeVolume)}
          </span>
        </div>
        <div className="size-0-75 nowrap color-gray-darken-1">
          Keskitilavuus{" "}
          <span className="color-gray-darken-2">
            {formatVolume(averageTreeVolume)}/ha
          </span>
        </div>
      </h4>
    );
  }

  renderTimberDetails() {
    const { totalTreeVolume, averageTreeVolume } = this.props;
    return (
      <h2>
        <div className="size-1-5 margin-0-25 margin-bottom">
          Puuston kokonaistilavuus{" "}
          <span className="nowrap">{formatVolume(totalTreeVolume)}</span>
        </div>
        <div className="size-1 nowrap">
          Keskitilavuus{" "}
          <span className="nowrap">{formatVolume(averageTreeVolume)}/ha</span>
        </div>
      </h2>
    );
  }

  render() {
    if (this.props.isCodeSearch) {
      return (
        <div className="container container--collapse-first">
          <div className="row center-xl text-align-left">
            <div className="col-xs-12 col-xl-9">
              <div className="row">
                <div className="col-xs-12 col-m-6">
                  {this.renderTimberGraph()}
                </div>
                <div className="col-xs-12 col-m-6">
                  <table>
                    <thead>
                      <tr>
                        <th className="border-bottom border-color-border size-0-75 uppercase letter-spacing light color-gray-darken-1 padding-0-5 padding-left">
                          Puulaji
                        </th>
                        <th className="border-bottom border-color-border size-0-75 uppercase letter-spacing light color-gray-darken-1 padding-0-5 text-align-right">
                          Tukkia
                        </th>
                        <th className="border-bottom border-color-border size-0-75 uppercase letter-spacing light color-gray-darken-1 padding-0-5 padding-left-0 text-align-right">
                          Kuitua
                        </th>
                      </tr>
                    </thead>
                    <tbody className="child-borders-y">
                      {this.renderTimberTableRows()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row row--collapse">
          <div className="col-xs-12">{this.renderTimberDetails()}</div>
          <div className="col-xs-12 col-l-6">
            <div className="margin-1 margin-bottom">
              {this.renderTimberGraph()}
            </div>
          </div>
          <div className="col-xs-12 col-l-6">
            <table>
              <thead>
                <tr>
                  <th className="border-bottom border-color-border size-0-75 uppercase letter-spacing light color-gray-darken-1 padding-0-5 padding-left">
                    Puulaji
                  </th>
                  <th className="border-bottom border-color-border size-0-75 uppercase letter-spacing light color-gray-darken-1 padding-0-5 text-align-right">
                    Tukkia
                  </th>
                  <th className="border-bottom border-color-border size-0-75 uppercase letter-spacing light color-gray-darken-1 padding-0-5 padding-left-0 text-align-right">
                    Kuitua
                  </th>
                </tr>
              </thead>
              <tbody className="child-borders-y">
                {this.renderTimberTableRows()}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
}

PropertyDetailsTimber.propTypes = {
  averageTreeVolume: PropTypes.number.isRequired,
  priceCalculations: PropTypes.arrayOf(
    PropTypes.shape({
      percent_from_total_volume: PropTypes.number.isRequired,
      values: PropTypes.object.isRequired,
      volumes: PropTypes.object.isRequired
    })
  ).isRequired,
  timberTypeAllocation: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  totalTreeVolume: PropTypes.number.isRequired,
  isCodeSearch: PropTypes.bool
};

export default PropertyDetailsTimber;
