import React, { Component } from "react";
import PropTypes from "prop-types";
import { ResponsiveContainer, PieChart, Pie } from "recharts";
import { formatPercentage } from "../../helpers/percentage";

class PercentagePieChart extends Component {
  renderLabel(props) {
    let x;
    if (props.textAnchor === "start") {
      x = props.x + 30;
    } else if (props.textAnchor === "end") {
      x = props.x - 30;
    } else {
      x = props.x;
    }

    return (
      <g>
        <text
          textAnchor="middle"
          y={props.y - 20 + 15}
          alignmentBaseline="middle"
          className="recharts-pie-label-text">
          <tspan x={x}>{props.title}</tspan>
        </text>
        <text
          textAnchor="middle"
          y={props.y + 15}
          alignmentBaseline="middle"
          className="recharts-pie-label-value">
          <tspan x={x}>{formatPercentage(props.value)}</tspan>
        </text>
      </g>
    );
  }

  render() {
    const height = 300;
    return (
      <div className="counter-pie-chart">
        <ResponsiveContainer height={height}>
          <PieChart height={height}>
            <Pie
              startAngle={90}
              endAngle={450}
              data={this.props.data}
              cx="50%"
              cy="50%"
              innerRadius={height - 206}
              outerRadius={height - 188}
              labelLine={false}
              label={this.renderLabel}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

PercentagePieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
};

export default PercentagePieChart;
