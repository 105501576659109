import React, {Component} from "react";
import $ from "jquery";

class TransferFailed extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  message() {
    if (this.props.transferStatus === "mekeApiError") {
      return "Sinulla ei ole metsäkiinteistöjä tai niiden siirto epäonnistui. Tarkasta kiinteistötietosi Metsään.fi -palvelussa";
    } else {
      return "Emme saaneet yhteyttä Metsään.fi -palveluun, eikä tietoja voitu siirtää. Metsään.fi:ssä saattaa olla ongelmia, yritä myöhemmin uudelleen.";
    }
  }

  render() {
    return (
      <div className="padding-1-5 padding-y text-align-center">
        <div className="margin-0-75 margin-bottom">
          <i className="opux-icon opux-icon-circled opux-icon-help size-74 color-primary" />
        </div>
        <h1 className="size-1-5 margin-0-5 margin-bottom">
          Tietoja ei voitu hakea
        </h1>
        <p className="size-1 color-gray-darken-1">
          {this.message()}
        </p>
        <div className="margin-0-75 margin-top">
          <a href="/success" className="opux-btn opux-btn-large opux-btn-primary">Yritä uudelleen</a>
        </div>
      </div>
    );
  }

}

TransferFailed.propTypes = {
};

export default TransferFailed;
